import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../../config';
import { NamedLink } from '../../../components';
import { createImageVariantConfig } from '../../../util/sdkLoader';
import css from './SectionFeaturedExperts.module.css';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

function SectionFeaturedExperts(props) {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    sdk.listings
      .query({
        include: ['author', 'images'],
        'fields.listing': [
          'title',
          'geolocation',
          'price',
          'publicData',
          'createdAt',
          'description',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      })
      .then(res => {
        const listings = res.data.data;
        const images = res.data.included.filter(i => {
          return i.type === 'image';
        });
        const users = res.data.included.filter(i => {
          return i.type === 'user';
        });
        listings.forEach(l => {
          const imageId = l.relationships.images.data[0].id.uuid;
          const authorId = l.relationships.author.data.id.uuid;

          const luckyImage = images.find(i => {
            return i.id.uuid === imageId;
          });

          const author = users.find(u => {
            return u.id.uuid === authorId;
          });
          l.author = author;
          l.images = [luckyImage];
        });

        if (res.data.data.length > 0) {
          const finalListingsArray =
            res.data.data.length >= 3
              ? res.data.data.slice(0, 3)
              : [res.data.data[0], res.data.data[0], res.data.data[0]];
          setListings(finalListingsArray);
        }
      })
      .catch(e => console.log(e));
  }, []);
  return (
    <div className={css.sectionWrapper}>
      <div className={css.sectionTop}>
        <FormattedMessage id="SectionFeaturedExperts.title" />
      </div>
      <div className={css.sectionBottom}>
        {listings.map(l => {
          const listingDesciption =
            l.attributes.description && l.attributes.description.length > 60
              ? l.attributes.description.slice(0, 57) + '...'
              : l.attributes.description;
          return (
            <NamedLink
              name="ListingPage"
              params={{ id: l.id.uuid, slug: l.attributes.title.replace(' ', '-') }}
              className={css.simpleListingCardWrapper}
            >
              <img
                className={css.listingBackgroundImage}
                src={l.images[0].attributes.variants['landscape-crop2x'].url}
              />
              <div className={css.textBox}>
                <div className={css.textBoxTitle}>{l.attributes.title}</div>
                <div className={css.textBoxBody}>{listingDesciption}</div>
              </div>
            </NamedLink>
          );
        })}
      </div>
    </div>
  );
}

export default SectionFeaturedExperts;
