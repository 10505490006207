import React, { useState, useEffect } from 'react';
import css from './SectionNeedHelp.module.css';
import { Button } from '../../../components';
import { post } from '../../../util/api';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { onSendConciergeQuestion } from '../../ListingPage/ListingPage.duck';

const SectionNeedHelpComponent = props => {
  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmitMessageForm = () => {
    const { onSendConciergeQuestion, currentUser } = props;
    const trimmedMessage = message?.trim();
    onSendConciergeQuestion(currentUser, trimmedMessage)
      .then(() => {
        setMessage('');
        setSuccess(true);
        setError(false);
      })
      .catch(err => {
        console.log(err);
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <div className={css.sectionWrapper}>
      <div className={css.sectionContent}>
        <div className={css.sectionText}>
          <p className={css.title}>Concierge service</p>
          <p className={css.description1}>Need some help?</p>
          <p className={css.description2}>
            We're at your service. Talk through your needs, goals, and vision with our team, then
            let us play matchmaker.
          </p>
        </div>

        <div className={css.form}>
          <input
            value={message ?? ''}
            onChange={e => setMessage(e.target.value)}
            className={css.inputStyleLong}
            type="textarea"
            placeholder="WHAT ARE YOU LOOKING FOR HELP WITH?"
          />
          <Button disabled={!message} className={css.submitButton} onClick={onSubmitMessageForm}>
            SUBMIT
          </Button>
          {success && (
            <p className={css.success}>
              Successfully submitted! Someone from our team will get back to you shortly.
            </p>
          )}
          {error && <p className={css.error}>There has been an error, please try again.</p>}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onSendConciergeQuestion: (customerInfo, message) =>
    dispatch(onSendConciergeQuestion(null, null, customerInfo, message)),
});

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const SectionNeedHelp = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SectionNeedHelpComponent);

export default SectionNeedHelp;
